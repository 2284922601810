// Fonts
$quicksand: 'Quicksand', sans-serif;

// colors
$orange: #ffa500;
$green: #57b846;
$success-text: #4caf50;
$red: #ea4355;
$error-text: #F44336;
$blue: #1da1f2;
$off-black: #333;
$white: #fff;
$off-white: #f8f8f8;

// Sizes
$xs: 0.5rem;
$normal: 1rem;
$sm: 1.5rem;
$md: 2rem;
$lg: 3rem;
$xlg: 5rem;
$xxlg: 7rem;

// Button Radius
$button-radius: 30px;

// Breakpoints
$lg-breakpoint: 1024px;
$md-breakpoint: 768px;
$sm-breakpoint: 641px;
$xs-breakpoint: 480px;

// Shadow
$shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);