.questions {
    background-color: $off-white;
    border-left: 5px solid $blue;
    width: 80%;
    margin: $lg auto 0 auto;
    padding: $sm $md;

    h2 {
        text-align: center;
        font-weight: lighter;
    }

    .lifeline-container {
        display: flex;
        justify-content: space-between;
    }

    .lifeline {
        position: relative;
        top: -3px;
    }

    .lifeline-icon {
        color: $green;
        cursor: pointer;
    }

    .lifeline-icon:hover {
        background: none;
        color: darken($orange, 10%);
    }

    h5 {
        font-size: 1.2rem;
        margin-bottom: lg;
        line-height: 1.5;
        text-align: center;
    }

    .options-container {
        display: inline-block;
        width: 50%;
    }

    .option {
        background-color: $blue;
        border-radius: $button-radius;
        color: $white;
        cursor: pointer;
        margin: $md auto;
        padding: $normal;
        transition: 0.3s linear all;
        text-align: center;
        width: 90%;
    }

    .option:hover {
        background-color: darken($blue, 20%);
    }
}

.button-container {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto $sm auto;
    padding: $md;
    width: 80%;
}

.button-container button {
    border: none;
    color: $off-white;
    cursor: pointer;
    font-family: $quicksand;
    margin-right: $md;
    padding: $xs $sm;
}

.button-container button:first-child {
    background-color: darken($blue, 10%);
    box-shadow: $shadow;
    transition: 0.2s linear all
}

.button-container button:first-child:hover {
    background-color: darken($blue, 20%);
}

.button-container button:nth-child(2) {
    background-color: $green;
    box-shadow: $shadow;
    transition: 0.2s linear all
}

.button-container button:nth-child(2):hover {
    background-color: darken($green, 10%);
}

.button-container button:last-child {
    background-color: $red;
    box-shadow: $shadow;
    transition: 0.2s linear all
}

.button-container button:last-child:hover {
    background-color: darken($red, 10%);
}


// .image-container{
//     // background-color: red;
// }


img{
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;

    // height: 100%;

}

@media (max-width: 768px) {

    .questions {
        background-color: $off-white;
        border-left: 5px solid $blue;
        width: 90%;
        margin: $lg auto 0 auto;
        padding: $sm $md;
    }

    .button-container {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 0 0;
        padding: $md;
        width: 80%;
    }

    .button-container button {
        border: none;
        color: $off-white;
        cursor: pointer;
        font-family: $quicksand;
        // margin-right: $md;
        margin-left: -25px;
        // padding: $xs $sm;
        // width: 40%;
    }
    
    img{
        display: block;
      margin-left: auto;
      margin-right: auto;
      width: 95%;
    
        // height: 100%;
    
    }
    // #home section {
    //     padding: 0 $normal;
    //     width: 100%;
    //     height: 100%;
    // }
}